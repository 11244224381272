<template>
  <div>
    <nuxt-link v-if="addLink(index)" :to="linkUrl(index)">
      <img
        :src="require(`../assets/img/banner/banner_${index}.png`)"
      >
    </nuxt-link>
    <img
      v-else
      :src="require(`../assets/img/banner/banner_${index}.png`)"
      class="img-width"
    >
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  methods: {
    addLink(index) {
      return index === 5 || index === 10 || index === 12 || index === 16 || index === 17 || index === 18;
    },
    linkUrl(index) {
      switch (index) {
        case 5:
        case 17:
          return '/vlaardingen-bedankt';
        case 10:
        case 16:
          return '/maassluis-bedankt';
        case 12:
        case 18:
          return '/schiedam-bedankt';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="stylus">
.img-width
  width 100%
</style>
