<template>
  <div>
    <v-container class="py-0">
      <img
        v-if="banner"
        :src="banner"
        :class="{'cursor-pointer': bannerUrl}"
        alt=""
        @click="goToBannerUrl"
      >
      <template v-else>
        <div v-show="$vuetify.breakpoint.smAndUp" class="grid">
          <!-- Geschenken banner [disabled] -->
          <!--<temporary-banner v-for="index in 20" :index="index" :key="index"/>-->
          <!-- Normale banner -->
          <template>
            <template v-for="index in peopleAmount">
              <div :key="index">
                <div>
                  <img :src="getImage(index)" :alt="'persoon-' + index">
                </div>
              </div>
            </template>
            <v-layout class="banner--location maassluis">
              Maassluis
            </v-layout>
            <v-layout class="banner--location vlaardingen">
              Vlaardingen
            </v-layout>
            <v-layout class="banner--location schiedam">
              Schiedam
            </v-layout>
            <v-layout class="banner--location banner-text pa-3">
              <h2 class="error--text">Wij zijn...</h2>
            </v-layout>
            <v-layout class="banner--location banner-logo pa-3">
              <img :alt="settings.name" src="~/assets/img/logo_header.png">
            </v-layout>
            <v-layout v-if="video && video.published" class="banner--location youtube">
              <v-flex text-xs-center px-3>
                <h2 class="secondary--text">Video</h2>
                <v-icon
                  color="red lighten-2"
                  x-large
                  dark
                  @click.stop="showDialog = true"
                >
                  fab fa-youtube
                </v-icon>
              </v-flex>
            </v-layout>
          </template>
          <div class="searchbar">
            <form class="layout justify-center" method="post" @submit.prevent>
              <v-flex xs21 md6>
                <h2 class="font-italic">Wij zorgen dat het klikt!</h2>
                <v-text-field id="vacature-zoeken" v-model="searches" class=" hidden-sm-and-down mt-3" solo small
                              label="Zoek in vrijwilligersvacatures"
                              single-line hide-details
                              @keyup.enter.native="searches !== '' && $refs.submit.$el.click()">
                  <v-btn slot="append" ref="submit" large depressed dark color="primary"
                         class="ma-0 pa-2 v-btn-search" nuxt @click="searchVacancies">
                    <v-icon medium>fas fa-search</v-icon>
                  </v-btn>
                </v-text-field>
              </v-flex>
            </form>
          </div>
        </div>
      </template>
      <div v-show="!$vuetify.breakpoint.smAndUp">
        <div class="mobile-logo">
          <img :alt="settings.name" src="~/assets/img/logo_header.png">
        </div>
      </div>
    </v-container>
    <video-modal v-if="video.published" :video="video" :show-dialog.sync="showDialog"/>
  </div>
</template>

<script>
import TemporaryBanner from '@/components/TemporaryBanner';
import VideoModal from '~/components/VideoModal';
import {mapGetters, mapState} from 'vuex';

export default {
  components: {TemporaryBanner, VideoModal},
  props: {
    banner: {
      type: String,
      default: null,
    },
    bannerUrl: {
      type: String,
      default: null,
    },
    video: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      queued: {},
      imageIndex: 0,
      people: [],
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters(['getPeople']),
    ...mapState({
      settings: state => state.settings,
      filter: state => state.vacancies.filter,
    }),
    peopleAmount() {
      return this.video && this.video.published ? 14 : 15;
    },
    searches: {
      set(searches) {
        if (this.queued.searches) {
          clearTimeout(this.queued.searches);
        }

        this.queued.searches = setTimeout(
          () => {
            this.pageNr = 1;
            this.$store.dispatch('vacancies/resetFilters');
            this.$store.commit('vacancies/SET_SEARCHES', searches);
          },
          250
        );
      },
      get() {
        return this.filter.searches;
      },
    },
  },
  created() {
    this.searches = '';
    this.people = this.getPeople(this.peopleAmount);
  },
  methods: {
    goToBannerUrl() {
      if (this.bannerUrl) {
        window.location.href = this.bannerUrl;
      }
    },
    showImage(i) {
      return ![3, 6, 7, 9, 12, 16].includes(i) && this.people[i]
    },
    getImage(count) {
      const index = count - 1;
      return this.people[index] ? this.people[index].image : '';
    },
    searchVacancies() {
      this.$store.dispatch('vacancies/getVacancies');
      this.$router.push({name: 'vacatures'});
    },
  },
};
</script>

<style scoped lang="stylus">
@import '~assets/style/colors'

columns = 8

>>> .v-input__control
  .v-input__slot
    height 20px
    padding-right 0 !important
    margin-left 30px


  .v-btn-search
    transform scale(1.3)

.cursor-pointer
  cursor pointer

.mobile-logo {
  display flex
  justify-content center

  img {
    max-width 50%
    height 100px
  }
}

.grid
  display -ms-grid
  display grid
  -ms-grid-columns 1fr 1fr 1fr 1fr
  grid-template-columns repeat(4, 1fr)
  grid-gap 10px

  > div
    min-height 132px

  for index in (1..(columns * 3))
    img:nth-child({index})
      if index > (columns * 2)
        -ms-grid-row 3
        -ms-grid-column index - (columns * 2)
      else if index > columns
        -ms-grid-row 2
        -ms-grid-column index - columns
      else
        -ms-grid-row 1
        -ms-grid-column index

  for index in (1..(columns * 3))
    div:nth-child({index})

      if (index % 5) == 0
        background-color primary
      else if (index % 5) == 1
        background-color secondary
      else if (index % 5) == 2
        background-color error
      else if (index % 5) == 3
        background-color tertiary

      else if (index % 5) == 4
        background-color accent

  .banner--location
    color theme-white
    font-style italic
    align-items center
    justify-content center

  .maassluis
    grid-area 1 / 3 / 2 / 4
    background-color tertiary !important

  .vlaardingen
    grid-area 3 / 4 / 4 / 5
    background-color secondary !important

  .schiedam
    grid-area 2 / 1 / 3 / 2
    background-color accent !important

  .youtube
    grid-area 3 / 6 / 4 / 7
    background-color grey-light !important

    div {
      background-color grey-light !important
    }

  .banner-text
    grid-area 1 / 6 / 2 / 7
    background-color grey-light !important

  .banner-logo
    grid-area 1 / 7 / 2 / 8
    background-color grey-light !important

  .searchbar
    grid-area 2 / 3 / 3 / 7
    background-color transparent !important

    form
      height 100%
      align-items center

    div
      background-color transparent !important

@media (min-width 768px)
  .grid
    -ms-grid-columns 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
    grid-template-columns repeat(columns, 1fr)

@media (max-width 1264px)
  .grid
    -ms-grid-columns 0 0 1fr 1fr 1fr 1fr 0 0
    grid-template-columns 0 0 repeat((columns - 4), 1fr) 0 0

    > div
      min-height 50px

    .searchbar
      grid-area 2 / 4 / 3 / 6

    .schiedam
      grid-area 3 / 3 / 4 / 4

    .banner-text
      grid-area 1 / 5 / 2 / 6

    .banner-logo
      grid-area 1 / 6 / 2 / 7
</style>
