<template>
  <v-layout column align-center>
    <v-avatar size="100">
      <img :src="getFeaturedPerson.image" :alt="getFeaturedPerson.title">
    </v-avatar>
    <div class="h4 font-weight-bold">{{ getFeaturedPerson.title }}</div>
    <div class="h4 font-weight-bold">{{ getFeaturedPerson.occupation }}</div>
    <div class="h4 font-weight-bold">{{ getFeaturedPerson.location }}</div>
  </v-layout>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['getFeaturedPerson']),
  },
};
</script>

<style lang="stylus" scoped>
  @import '~assets/style/colors'

  .pas
    position relative
    z-index 1

    h2
      line-height 1


@media (min-width:960px)

  .pas

    .pas-content
      position absolute
      top 0
      right 0
      color primary
      z-index 1
      padding 3rem 3rem 0
      width 235px
      height calc(770px / 2)
      margin 0

      h4
        text-align center


@media (min-width:1264px)

  .pas

    &:after
      content ''
      width 270px

    .pas-content
      padding 5rem 3rem 0
      width 270px

</style>
