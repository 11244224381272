<template>
  <div>
    <v-layout column py-4 class="item">
      <v-flex class="overlay">
        <div class="overlay-content d-sm-flex text-xs-right">
          <div>
            <p class="body-2 pl-5">{{ item.name }}<br> {{ item.function }}</p>
          </div>
          <img src="/triangle.svg" aria-hidden="true" class="bg-overlay" alt="">
          <img :src="item.image" :alt="item.title">
        </div>
      </v-flex>
      <v-flex :class="$vuetify.breakpoint.smAndDown ? 'pa-1': 'pa-5'" sm6 class="content">
        <h2 :class="$vuetify.breakpoint.xsOnly.isMounted ? '': 'mb-4'">{{ item.title }}</h2>
        <p v-if="$vuetify.breakpoint.mdAndUp">{{ item.text }}</p>
        <div v-else-if="$vuetify.breakpoint.smAndUp">
          <p>{{ item.text.substring(0, 200) + ".." }}</p>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'

.overlay

  .overlay-content
    position relative
    height 100%

    img

      &.bg-overlay
        position absolute
        right 0
        bottom 0
        z-index 1

    p
      position absolute
      right 16px
      bottom 0
      color white
      z-index 2

.content
  position relative
  background lightness(secondary, 95%)

  &:before, &:after
    content '\f10d'
    color: primary
    font-family "Font Awesome 5 Pro"
    font-size 2rem
    font-weight 700
    position absolute
    top -20px
    z-index 4
    @media (max-width: 600px)
      display none

  &:after
    content '\f10e'
    top initial
    bottom -20px
    right 48px

@media (min-width: 600px)

  .item
    flex-direction row

    .overlay
      width 50%

      .overlay-content
        width 100%

</style>
