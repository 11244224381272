<template>
  <v-flex v-bind="size" class="pa-2">
    <v-card :color="colors[index]" nuxt flat class="white--text fill-height">
      <template v-if="first">
        <v-card-text class="first align-start">
          <div>
            <h1 class="justify-center align-center">Uitgelichte vacatures</h1>
          </div>

        </v-card-text>
        <v-card-actions class="first px-3 pt-0 pb-3">
          <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted" to="/vacatures" nuxt color="greyLight" depressed
                 class="v-btn-vacatures">
            Alle vacatures
          </v-btn>
        </v-card-actions>
      </template>
      <v-layout v-else column fill-height ma-0>
        <v-card-text class="align-start">

          <v-clamp :max-lines="2" tag="h4" class="grow">{{ item.title }}</v-clamp>
          <v-clamp :max-lines="2" tag="p" class="grow">{{ item.city }}</v-clamp>

          <v-clamp :max-lines="4" class="body-1">{{ item.description }}</v-clamp>
        </v-card-text>
        <v-card-actions class="px-3 pt-0 pb-3">
          <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted"
                 :to="{ name: 'vacatures-slug', params: { slug: item.slug } }" nuxt depressed color="greyLight"
                 class="v-btn-vacatures primary--text">
            Bekijk vacature
          </v-btn>
        </v-card-actions>
      </v-layout>

    </v-card>
  </v-flex>
</template>

<script>
import VClamp from 'vue-clamp';

export default {
  components: {
    VClamp,
  },
  props: {
    first: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    size: {
      type: Object,
      default: () => ({
        xs12: true,
        sm6: true,
        md3: true,
      }),
    },
  },
  data() {
    return {
      colors: ['primary', 'secondary', 'accent', 'tertiary'],
    };
  },
};
</script>

<style lang="stylus" scoped>

.first h1
  font-size 2rem

.first .v-btn-vacatures
  bottom: 20px;
  position: absolute;

.v-card__text, .v-card__actions
  flex-grow 1

.v-card__actions
  align-items: flex-end;

</style>
