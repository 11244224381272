<template>
  <v-flex :class="{ 'pa-3' : $vuetify.breakpoint.smAndUp}" md3 xs6 class="event">
    <v-card v-if="first" nuxt flat
            class="event--first white--text layout wrap column fill-height align-center justify-center"
            style="display: flex;">
      <v-card-title>
        <div class="align-center justify-center">
          <h2>Agenda</h2>
          <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted"
                 :to="{ name: 'agenda' }" nuxt depressed color="greyLight"
                 class="v-btn-vacatures primary--text ml-0">
            Volledige agenda
          </v-btn>
        </div>
      </v-card-title>
    </v-card>
    <v-card
      v-else
      :to="{name: `${item._type === 'Training' || item._type === 'E-learning' ? 'academie' : 'agenda'}-slug`, params: {slug: item.slug}}"
      :color="colors[index]" nuxt flat class="white--text layout wrap column fill-height" style="display: flex;">
      <v-card-title class="text-xs-center">
        <div>
          <div class="py-4">
            <p class="day">{{ $moment(item.date).format('D') }}</p>
            <p class="month">{{ $moment(item.date).format('MMMM') }}</p>
          </div>
          <div class="py-4">
            <h4>{{ item.title }}</h4>
          </div>
        </div>
      </v-card-title>
      <v-card-actions class="event_button text-xs-center">
        <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted"
               :to="{name: `${item._type === 'Training' ? 'academie' : 'agenda'}-slug`, params: {slug: item.slug}}" nuxt
               depressed color="theme-white"
               class="v-btn-vacatures primary--text">
          Bekijken
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    first: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: ['secondary', 'accent', 'tertiary', 'primary'],
    };
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/style/colors'
.event--first
  background linear-gradient(to top left, #153a63 50%, #002854 50%)

.event_button
  right -15px
  bottom -15px
  position absolute

.day
  font-size 4rem
  line-height .7
  color theme-white

.month
  font-weight 700
  color theme-white

@media (max-width 600px)
  .event
    display block !important
    margin-left auto
    margin-right auto

  .event--first
    min-height 250px

@media (min-width 768px)

  .day
    font-size 3.4rem

@media (min-width 992px)

  .day
    font-size 5rem

</style>
