<template>
  <div>
    <v-dialog
      v-model="showDialog"
      :width="$vuetify.breakpoint.smAndDown ? '100vw': '70vw'"
      lazy
    >
      <v-card>
        <v-card-title class="d-flex justify-space-between lighten-2 secondary" style="flex-wrap: nowrap;">
          <h2 class="white--text">{{ video.title }}</h2>
          <v-icon color="white" @click.stop="closeDialog()">fa fa-times</v-icon>
        </v-card-title>

        <v-card-text>
          <iframe
            v-if="showDialog"
            :src="`https://www.youtube.com/embed/${video.id}?&autoplay=1`"
            :height="$vuetify.breakpoint.mdAndDown ? '450px' : '600px'"
            width="100%"
            allowfullscreen
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    video: {
      type: Object,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$emit('update:show-dialog', true);
    console.log(this._uid);
  },
  methods: {
    closeDialog() {
      this.$emit('update:show-dialog', false);
    },
  },
};
</script>
