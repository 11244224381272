<template>
  <div>
    <header-home :banner="page.image" :banner-url="page.banner_url" :video="video" class="pb-3"/>
    <v-layout :class="{'pa-3': $vuetify.breakpoint.smAndDown}" class="bg-toggle pb-0" tag="section">
      <v-container>
        <client-only>
          <slick ref="slickVacancies" :options="slickOptions" class="layout row wrap">
            <vacancy v-if="$vuetify.breakpoint.smAndUp" :key="'all'" :index="0" :first="true"/>

            <vacancy v-for="(vacancy, index) in vacancies.slice(0,3)" :key="vacancy.id" :item="vacancy"
                     :index="index +1"/>
          </slick>
        </client-only>
      </v-container>
    </v-layout>
    <v-container py-0 mt-5>
      <h2 class="font-italic">De KLiK van...</h2>
    </v-container>
    <v-layout>
      <v-container py-0 px-5>
        <client-only>
          <slick ref="slickTestimonials" :options="slickTestimonialOptions" class="layout row wrap5 px-5">
            <testimonial v-for="testimonial in testimonials" :key="testimonial.id" :item="testimonial"/>
          </slick>
        </client-only>
      </v-container>
    </v-layout>
    <v-layout :class="{'pa-3': $vuetify.breakpoint.smAndDown}" tag="section">
      <v-container>
        <v-layout row wrap pb-3>
          <v-flex md6>
            <v-layout row wrap justify-space-between>
              <v-flex shrink>
                <h2 class="font-italic d-inline">Het laatste nieuws</h2>
              </v-flex>
              <v-flex shrink>
                <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted" :to="{name: 'nieuws'}" nuxt depressed
                       color="primary"
                       class="mt-0">
                  Bekijk al het nieuws
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <client-only>
            <v-flex sm12 md6 mb-5>
              <v-layout v-if="$vuetify.breakpoint.smAndUp" row class="posts">
                <v-flex v-for="(post, index) in posts" :key="post.id" md6>
                  <post :item="post" :index="index" :on-home="true"/>
                </v-flex>
              </v-layout>
              <div v-else>
                <slick ref="slickPosts" :options="slickOptions" class="layout row wrap ">
                  <post v-for="(post, index) in posts" :key="post.id" :item="post" :index="index" :on-home="true"/>
                </slick>
              </div>
            </v-flex>
          </client-only>
          <v-flex sm12 md6 mb-5>
            <v-layout row wrap fill-height class="trainingen">
              <v-flex xs12 sm6 align-center pa-4 class="layout text-md-center">
                <div>
                  <h3>Nog meer plezier halen uit je vrijwilligerswerk?</h3>
                </div>
              </v-flex>
              <v-flex xs12 sm6 class="academie-1"/>
              <v-flex xs12 sm6 hidden-xs-only class="academie-2"/>
              <v-flex xs12 sm6 align-center pa-4 class="layout text-md-center">
                <v-flex align-center>

                  <h3>Ontwikkel je vaardigheden bij de vrijwilligersacademie</h3>
                  <v-btn :block="$vuetify.breakpoint.smAndDown.isMounted" :to="{name: 'academie'}" nuxt depressed
                         color="grey-light"
                         class="-mb-1">
                    Bekijk alle trainingen
                  </v-btn>

                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
    <v-layout class="teaser-home white--text" pb-0 tag="section">
      <v-container class="pb-0 mb-0">
        <v-layout row wrap>
          <v-flex xs5 md6 order-md2>
            <div class="pas">
              <PasContent class="pas-content hidden-sm-and-down"/>
              <v-layout>
                <v-flex v-if="!user || !user.pass" md5 text-md-right>
                  <h2>Heeft u al een vrijwilligerspas?</h2>
                  <v-btn href="/vrijwilligerspas" flat large color="white" class="ma-0 px-2">
                    Aanvragen
                    <v-icon right small>far fa-long-arrow-alt-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <v-flex xs7 md5 order-md1>
            <div class="teaser-content">
              <v-layout row wrap align-end class="content">
                <v-flex xs3 sm2 md4>
                  <img src="/questionmark.png" alt="Vragen of een afspraak maken?">
                </v-flex>
                <v-flex xs7 md5 class="mt-4">
                  <h2>{{ page.titel_teaser }}</h2>
                  <v-btn :href="page.link_url_teaser" flat large color="accent" class="ma-0  px-2">
                    {{ page.link_teaser }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-layout>
    <v-layout :class="{'pa-3': $vuetify.breakpoint.smAndDown}" class="events_background pt-5 pb-3" tag="section">
      <v-container>
        <v-layout justify-space-between align-center class="hidden-sm-and-down">
          <v-flex>
            <h2 class="font-italic">Agenda</h2>
          </v-flex>
        </v-layout>
        <client-only>
          <slick ref="slickEvents" :options="slickOptions" :class="{'events': $vuetify.breakpoint.smAndUp}" class="layout row wrap mt-4">
            <event :first="true"/>
            <event v-for="(event, index) in events" :key="event.id" :item="event" :index="index"/>
          </slick>
        </client-only>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
import HeaderHome from '~/components/HeaderHome';
import Vacancy from '~/components/Vacancy';
import Post from '~/components/Post';
import Event from '~/components/Event';
import PasContent from '~/components/PasContent';
import Testimonial from '~/components/Testimonial';

export default {
  auth: false,
  layout: 'home',
  components: {
    HeaderHome,
    Vacancy,
    Post,
    Event,
    PasContent,
    Testimonial,
  },
  data() {
    return {
      showVacancy: 0,
      showPost: 0,
      showEvent: 0,
      slickOptions: {
        mobileFirst: true,
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Vorige" type="button" style="z-index: 2;"><i class="v-icon far fa-long-arrow-alt-left"/>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Volgende" type="button" style="z-index: 2"><i class="v-icon far fa-long-arrow-alt-right"/>',
        responsive: [
          {
            breakpoint: this.$vuetify.breakpoint.thresholds.xs,
            settings: 'unslick',
          },
        ],
      },
      slickTestimonialOptions: {
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Vorige" type="button" style="z-index: 2;"><i class="v-icon far fa-arrow-left fa-3x"/></button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Volgende" type="button" style="z-index: 2"><i class="v-icon far fa-arrow-right fa-3x"/></button>',
      },
    };
  },
  computed: {
    video() {
      const {video_id: id, video_titel: title, publiceer_video: published } = this.page || {}
      return {
        id,
        title,
        published,
      }
    },
  },
  async asyncData({$axios, app}) {
    try {
      let [testimonialRes, postRes, eventRes, pageRes] = await Promise.all([
        $axios.get(`/testimonials`),
        $axios.get(`/berichten?limit=2`),
        $axios.get(`/agenda?limit=3`),
        $axios.get(`/page`),
      ]);

      return {
        testimonials: testimonialRes.data,
        vacancies: pageRes.data.data.vacancies,
        posts: postRes.data,
        events: app.$_.sortBy(Object.values(eventRes.data), 'date'),
        page: pageRes.data.data,
      };
    } catch (e) {
      app.$bugsnag.notify(e);

      return {
        testimonials: [],
        vacancies: [],
        posts: [],
        events: [],
        page: {meta: {}},
      };
    }
  },
  head() {
    return {
      title: this.page.meta.title,
      meta: [
        this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
};
</script>

<style scoped lang="stylus">
@import '~assets/style/colors'

.events
  margin-left -16px
  margin-right -16px

.v-btn-vacatures
  transform: translateY(50%);

.bg-toggle
  background-color white

.events_background
  background linear-gradient(180deg, transparent 40%, grey-light 40%)

.trainingen .flex
  background-color primary
  color theme-white
  z-index 5

.academie-1
  background url("~assets/img/academie_1.png")
  background-size cover
  min-height 200px

.academie-2
  background url("~assets/img/academie_2.png")
  background-size cover
  min-height 200px

.slick-slider

  >>> .v-card
    margin 0 10px

  >>> .slick-arrow
    position absolute
    top 50%
    display block
    background: white;
    border-radius: 50%;
    padding: 5px 6.5px;
    transform translateY(-50%)

  >>> .slick-next
    right -30px

  >>> .slick-prev
    left -30px

@media (min-width 768px)
  .bg-toggle
    background-color grey-light

  .teaser-home .container
    padding-top 6rem

  .pas-content
    position absolute
    top 100%
    right 10%
    color primary
    z-index 1

  h2
    font-size 1.5rem

.posts
  height 100%
</style>
