<template>
  <v-card v-href class=" pr-4 mb-4 post" flat height="100%">
    <div :class="colors[ (index % 4)]">
      <v-layout
        class="post-image pa-0">
        <img :src="item.thumb" :alt="item.title" :class="{ homePost: onHome } ">
      </v-layout>
      <div class="post-text">
        <v-card-text class="py-0 pl-4">
          <h4>{{ item.title }}</h4>
          <p class="font-italic mb-1 body-1">{{ $moment(item.date).format('D MMMM YYYY') }}</p>
          <div class="body-1 pb-1">{{ item.excerpt }}
            <nuxt-link :to="{ name: 'nieuws-slug', params: { slug: item.slug } }" class="font-weight-bold">Meer lezen
            </nuxt-link>
          </div>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
      },
    },
    index: {
      type: Number,
      default: 1,
    },
    onHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: ['accent', 'secondary', 'secaccent', 'primary'],
    };
  },
};
</script>

<style scoped lang="stylus">
@import '~assets/style/colors'
.post > div
  height 100%

.post-image
  img
    max-width 200px
    max-height 200px
    margin -10px -10px 0 auto




.post-text
  height 50%
  color theme-white
  a
    color theme-white



</style>
